import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { addCheckpointAsync, checkpointURLCheck } from '../checkpoints/checkpointsSlice';
import styles from './AddCheckpointForm.module.scss';

export function AddCheckpointForm(props) {
    const dispatch = useDispatch();

    const [checkpointCreator, setCheckpointCreator] = useState("pkolawa");
    // const [checkpointOwner, setCheckpointOwner] = useState("pkolawa");
    // const [checkpointThumbnail, setCheckpointThumbnail] = useState("");
    const [checkpointType, setCheckpointType] = useState("static");
    const [name, setName] = useState("");
    const [selector, setSelector] = useState("body > div.elementor.elementor-22083.elementor-location-single.post-17210.product.type-product.status-publish.has-post-thumbnail.product_cat-tkaniny-na-metry.pa_marka-fendi.pa_rodzaj-tkaniny-bawelna.first.instock.sale.taxable.shipping-taxable.purchasable.product-type-simple.product > section.elementor-section.elementor-top-section.elementor-element.elementor-element-a76aa24.elementor-section-boxed.elementor-section-height-default.elementor-section-height-default > div > div.elementor-column.elementor-col-50.elementor-top-column.elementor-element.elementor-element-38d761c > div > div.elementor-element.elementor-element-204cf32.elementor-widget.elementor-widget-woocommerce-product-price > div > p > ins > span > bdi");
    const [selectorType, setSelectorType] = useState("CSS");
    const [forceThumbnail, setForceThumbnail] = useState();
    const [URL, setURL] = useState("https://principi.pl/produkt/bawelna-zakardowa-fendi-roz-czern/");

    const [isPending, setIsPending] = useState("");
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleCheckpointURLCheck = (e) => {
        e.preventDefault();
        setIsPending(true);
        dispatch(checkpointURLCheck(e.target.value)).then( APIResponse => {
            if(!APIResponse.verificationPassed && APIResponse.errorMessage){
                setHasErrors(true);
                setErrorMessage(APIResponse.errorMessage);
            }
            setIsPending(false);
        });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsPending(true);
        const newCheckpointObject = {
            // checkpointCreator,
            // checkpointOwner,
            // checkpointThumbnail,
            checkpointType,
            name,
            selector,
            selectorType,
            URL,
            forceThumbnail
        };
        dispatch(addCheckpointAsync(newCheckpointObject)).then( APIResponse =>{
            setIsPending(false);
        });
    }

    const handleIsSubmitButtonDisabled = () => {
        const isAnyInputEmpty = [
            checkpointType,
            name,
            selector,
            selectorType,
            URL
        ].includes("");

        return (isAnyInputEmpty || isPending) ? 'disabled' : null
    }

    const hasErrorsContainer = hasErrors && errorMessage.length ? <p>{errorMessage}</p> : null

    return(
        <>
            {hasErrorsContainer}
            <form onSubmit={handleSubmit} className={styles.addCheckpointForm}>
                <label>
                    <span>Name</span>
                    <input 
                        type="text"
                        value={name} 
                        onChange={e => setName(e.target.value)}
                    />
                </label>
                <label>
                    <span>URL</span>
                    <input 
                        type="text"
                        value={URL} 
                        onChange={e => setURL(e.target.value)}
                        onBlur={e => handleCheckpointURLCheck(e)}
                    />
                </label>
                <label>
                    <span>Selector</span>
                    <input 
                        type="text"
                        value={selector} 
                        onChange={e => setSelector(e.target.value)}
                    />
                </label>
                {/* <label>
                    <span>Checkpoint Thumbnail</span>
                    <input 
                        type="text"
                        value={checkpointThumbnail} 
                        onChange={e => setCheckpointThumbnail(e.target.value)}
                    />
                </label> */}
                <label>
                    <span>Checkpoint Type</span>
                    <select
                        value={checkpointType}
                        onChange={e => setCheckpointType(e.target.value)}
                    >
                        <option value="static">Static</option>
                        <option value="dynamic">Dynamic</option>
                    </select>
                </label>
                <label>
                    <span>Selector Type</span>
                    <select
                        value={selectorType}
                        onChange={e => setSelectorType(e.target.value)}
                    >
                        <option value="CSS">CSS</option>
                        <option value="XPath">XPath</option>
                    </select>
                </label>
                <label>
                    <span>Add thumbnail</span>
                    <input type="checkbox" value={forceThumbnail} onChange={e => setForceThumbnail(e.target.checked)}/>
                </label>
                <input type="submit" value="Submit" className={styles.addCheckpointForm__submit} disabled={handleIsSubmitButtonDisabled()}/>
            </form>
        </>
    );
}