import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const axios = require('axios');

export const selectAllCheckpoints = state => state.checkpoints.list.map(checkpoint => checkpoint.checkpointID);

export const getCheckpointByID = (state, checkpointID) => {
	const selectedCheckpoint = state.checkpoints.list.filter(checkpoint => checkpoint.checkpointID === checkpointID);
	return selectedCheckpoint[0];
};

export const addCheckpointAsync = createAsyncThunk('checkpoints/addCheckpointAsync', async (checkpointFormData) => {
	const response = await axios.post(`${process.env.REACT_APP_REPO_API_URL}/checkpoint/add`, checkpointFormData);
	return response.data;
})

export const checkpointURLCheck = createAsyncThunk('checkpoint/url', async (checkpointURL) => {
	const response = await axios.post(`${process.env.REACT_APP_REPO_API_URL}/checkpoint/url`, {"URL": checkpointURL});
	return response.data;
})

export const getCheckpointAsync = createAsyncThunk('checkpoints/getCheckpointAsync', async (checkpointID) => {
	const response = await axios.get(`${process.env.REACT_APP_SCRAPERS_API_URL}/check/${checkpointID}`);

	const updateObject = {
		"latestValue": response.data.response.text,
		"lastCheck": response.data.timestamp,
	}

	if(response.data.response.screenshotCreated) {
		updateObject["checkpointThumbnail"] = `${process.env.REACT_APP_SCREENSHOTS_BASE_URL}/${response.data.response.screenshotID}${process.env.REACT_APP_SCREENSHOT_FILE_EXTENSION}`;
	}
	axios.put(`${process.env.REACT_APP_REPO_API_URL}/checkpoint/${checkpointID}`, updateObject).catch( err => console.error(err));
	return response.data
});

export const getUsersCheckpointsAsync = createAsyncThunk('checkpoints/getUsersCheckpointsAsync', async (username = "pkolawa") => {
	const response = await axios.get(`${process.env.REACT_APP_REPO_API_URL}/checkpoints/${username}`);
	return response.data;
});

export const deleteCheckpointAsync = createAsyncThunk('checkpoints/deleteCheckpointAsync', async (checkpointID) => {
	const response = await axios.delete(`${process.env.REACT_APP_REPO_API_URL}/checkpoint/${checkpointID}`);
	return response.data;
});

export const updateCheckpointAsync = createAsyncThunk('checkpoints/updateCheckpointAsync', async (checkpointObject) => {
	const response = await axios.put(`${process.env.REACT_APP_REPO_API_URL}/checkpoint/${checkpointObject.checkpointID}`, {...checkpointObject});
	return response.data;
});

export const checkpointsSlice = createSlice({
  name: 'checkpoints',
  initialState: {
	list: [],
	listStatus: 'idle',
	error: null
  },
  reducers: {
  },
  extraReducers: {
	  [addCheckpointAsync.pending]: (state, action) => {
		  const { arg, requestId } = action.meta;
		  state.list.push({
			  ...arg,
			  currentRequestId: requestId
		  });
	  },
	  [addCheckpointAsync.fulfilled]: (state, action) => {
		  const { requestId } = action.meta;
		  const ops = action.payload.checkpoints;
		  const newCheckpointsList = state.list.map( checkpoint => {
			if (checkpoint.currentRequestId === requestId) {
				return {
					...ops,
					currentRequestId: null
				}
			}
			return {...checkpoint}
		})
		state.list = newCheckpointsList;
	  },
	  [addCheckpointAsync.rejected]: (state, action) => {
		  const { requestId } = action.meta;
		  const failedCheckpointIndex = state.list.findIndex(checkpoint => checkpoint.currentRequestId === requestId);

		  state.list.splice(failedCheckpointIndex, 1);
		  state.error = action.error.message
	  },
	  [deleteCheckpointAsync.pending]: (state, action) => {
		  const { arg } = action.meta;
		  const pendingCheckpointIndex = state.list.findIndex(checkpoint => checkpoint.checkpointID === arg);

		  state.list[pendingCheckpointIndex].checkpointStatus = 'pending';
		  state.list[pendingCheckpointIndex].currentRequestId = action.meta.requestId;
	  },
	  [deleteCheckpointAsync.fulfilled]: (state, action) => {
		  const { requestId } = action.meta;
		  const removedCheckpointIndex = state.list.findIndex(checkpoint => checkpoint.currentRequestId === requestId);

		  state.list.splice(removedCheckpointIndex, 1)
	  },
	  [deleteCheckpointAsync.rejected]: (state, action) => {
		  const { requestId } = action.meta
		  const removedCheckpointIndex = state.list.findIndex(checkpoint => checkpoint.currentRequestId === requestId);

		  state.list[removedCheckpointIndex].checkpointStatus = 'ready';
		  state.list[removedCheckpointIndex].currentRequestId = null;
	  },
	  [getCheckpointAsync.pending]: (state, action) => {
		  const { arg } = action.meta;
		  const pendingCheckpointIndex = state.list.findIndex(checkpoint => checkpoint.checkpointID === arg);
		  
		  state.list[pendingCheckpointIndex].checkpointStatus = 'pending';
		  state.list[pendingCheckpointIndex].currentRequestId = action.meta.requestId;
	  },
	  [getCheckpointAsync.fulfilled]: (state, action) => {
		const { requestId } = action.meta;
		const { timestamp, response } = action.payload;
		const newCheckpointsList = state.list.map( checkpoint => {
			if (checkpoint.currentRequestId === requestId) {
				return {
					...checkpoint,
					checkpointStatus: 'ready',
					currentRequestId: null,
					latestValue: response ? response.text : "NOT SPECIFIED",
					lastCheck: timestamp,
					checkpointThumbnail: response.screenshotCreated ? `${process.env.REACT_APP_SCREENSHOTS_BASE_URL}/${response.screenshotID}${process.env.REACT_APP_SCREENSHOT_FILE_EXTENSION}` : checkpoint.checkpointThumbnail,
					previousValue: checkpoint.latestValue
				}
			}
			return {...checkpoint}
		})
		state.list = newCheckpointsList;
	  },
	  [getCheckpointAsync.rejected]: (state, action) => {
		  state.error = action.error.message
	  },
	  [getUsersCheckpointsAsync.pending]: (state, action) => {
		  state.listStatus = 'loading'
	  },
	  [getUsersCheckpointsAsync.fulfilled]: (state, action) => {
		state.listStatus = 'succeeded';
		state.list = action.payload.checkpoints.map((checkpoint) => {
			return {
				...checkpoint, 
				checkpointStatus: 'ready'
			}
		});
	  },
	  [getUsersCheckpointsAsync.rejected]: (state, action) => {
		state.listStatus = 'failed'
		state.error = action.error.message
	  },
	  [updateCheckpointAsync.pending]: (state, action) => {
		const { arg } = action.meta;
		const pendingCheckpointIndex = state.list.findIndex(checkpoint => checkpoint.checkpointID === arg);
		
		state.list[pendingCheckpointIndex].checkpointStatus = 'pending';
		state.list[pendingCheckpointIndex].currentRequestId = action.meta.requestId;
	  },
	  [updateCheckpointAsync.fullfilled]: (state, action) => {
		const { requestId } = action.meta
		const updatedCheckpointIndex = state.list.findIndex(checkpoint => checkpoint.currentRequestId === requestId);

		state.list[updatedCheckpointIndex].checkpointStatus = 'ready';
		state.list[updatedCheckpointIndex].currentRequestId = null;
	  },
	  [updateCheckpointAsync.rejected]: (state, action) => {
		const { requestId } = action.meta
		const updatedCheckpointIndex = state.list.findIndex(checkpoint => checkpoint.currentRequestId === requestId);

		state.list[updatedCheckpointIndex].checkpointStatus = 'ready';
		state.list[updatedCheckpointIndex].currentRequestId = null;
	  }
  }
});

export const { setCheckpointPending } = checkpointsSlice.actions;




export default checkpointsSlice.reducer;
