import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCheckpoint,
  removeCheckpoint,
  updateCheckpoint,
  getUsersCheckpointsAsync,
  selectAllCheckpoints,
} from './checkpointsSlice';
import Checkpoint from './Checkpoint';
import styles from './Checkpoints.module.scss';

export function Checkpoints() {
  const dispatch = useDispatch();
  const checkpointsIDList = useSelector(selectAllCheckpoints);
  const checkpointsStatus = useSelector(state => state.checkpoints.listStatus);

  useEffect(() => {
      if (checkpointsStatus === 'idle') {
          dispatch(getUsersCheckpointsAsync());
      }
  }, [checkpointsStatus, dispatch]);
  
  let content;

  if(checkpointsStatus === 'loading'){
      content = <div>Loading</div>;
  } else if(checkpointsStatus === 'succeeded') {
      content = <ul className={styles.checkpointsList}>{checkpointsIDList.map( (checkpointID, index) => {
            return (<Checkpoint checkpointID={checkpointID} key={index}/>);
        })}
      </ul>;
  } else if(checkpointsStatus === 'failed') {
      content = <div>Error</div>;
  }

  return (
    <>
        {content}
    </>
  );
}
