import React from 'react';
import { Checkpoints } from './features/checkpoints/Checkpoints';
import { AddCheckpointForm } from './features/add/AddCheckpointForm';
import { ReactComponent as Logo } from './assets/svg/logo2.svg';
import './App.scss';

function watchaApp() {
  return (
    <div id="learn" className="watchaApp">
      <header className="watchaApp-header">
        <Logo className="watchaApp-logo"/>
        <h1>Watcha</h1>
      </header>
      <main>
        <Checkpoints />
        <AddCheckpointForm />
      </main>
    </div>
  );
}

export default watchaApp;
