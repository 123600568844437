import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCheckpointAsync,
  getCheckpointAsync,
  getCheckpointByID
} from './checkpointsSlice';
import styles from './Checkpoint.module.scss';

export function Checkpoint(props) {
    const dispatch = useDispatch();
    const checkpointData = useSelector(state => getCheckpointByID(state, props.checkpointID));
    
    if(!checkpointData.latestValue && checkpointData.checkpointID && checkpointData.checkpointStatus !== 'pending') {
        dispatch(getCheckpointAsync(checkpointData.checkpointID));
    }
    
    let content;
    if(checkpointData.checkpointStatus === 'ready') {
        const convertedTimestamp = checkpointData.lastCheck ? new Date(checkpointData.lastCheck).toLocaleString() : null;
        content = <>
            <p className={styles.checkpointRow__latestValue}>{checkpointData.latestValue || null}</p>
            <p className={styles.checkpointRow__lastCheck}>{`${convertedTimestamp}`}</p>
        </>;

    } else if (checkpointData.checkpointStatus === 'pending'){
        content = <p>Pending</p>;
    } else {
        content = null;
    }

    const checkpointThumbnail = checkpointData.checkpointThumbnail ? <img src={checkpointData.checkpointThumbnail} className={styles.checkpointRow__thumbnail} alt={`${checkpointData.name} - thumbnail`} /> : null;

    return(<li className={`${styles.checkpointRow}`}>
        {checkpointThumbnail}
        <a href={checkpointData.URL} target="_blank" className={styles.checkpointRow__name}> {checkpointData.name} </a>
        {content}
        <button onClick={() => dispatch(getCheckpointAsync(checkpointData.checkpointID))} className={styles.checkpointRow__checkNowButton}>Check now</button>
        <button onClick={() => dispatch(deleteCheckpointAsync(checkpointData.checkpointID))} className={styles.checkpointRow__deleteButton}>DELETE</button>
    </li>)
}

export default Checkpoint;